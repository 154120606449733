<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
        <c-table
          ref="table"
          title="작업장소"
          tableId="riskHazrd01"
          :columns="grid.columns"
          :data="grid.data"
          :filtering="false"
          :editable="editable"
          :columnSetting="false"
          :usePaging="false"
          @uploadChange="setMap"
          @getTableHeight="(height) => { this.imgHeight = height }"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn v-if="editable" label="추가" icon="add" @btnClicked="add" />
              <c-btn 
                v-if="editable" 
                :isSubmit="isSave"
                :url="saveUrl"
                :param="grid.data"
                mappingType="PUT"
                label="저장" 
                icon="save" 
                @beforeAction="saveMap"
                @btnCallback="saveMapCallback"/>
            </q-btn-group>
          </template>
          <template v-slot:customArea="{ props, col }">
            <template v-if="col.name==='src'">
              <q-btn 
                round unelevated 
                size="6px"
                color="amber" 
                icon="search"
                @click="rowClick(props.row, props.pageIndex)" />
            </template>
            <template v-else>

            </template>
          </template>
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
        <c-card title="작업장소 지도 미리보기" class="cardClassDetailForm" :noMarginPadding="true" :height="setImgHeight"> 
          <template slot="card-button">
            <q-btn-group outline >
              <q-btn
                v-if="haveMap"
                class="custom-btn"
                size="md"
                text-color="white"
                color="teal-6"
                @click="toggle"
                :icon="$q.fullscreen.isActive ? 'fullscreen_exit' : 'fullscreen'"
                label="지도 크게 보기"
              ></q-btn>
            </q-btn-group>
          </template>
          <template slot="card-detail">
            <div class="col-12">
              <q-img :src="mapSrc" fit="scale-down" ref="image"
                :contain="true"
                :ratio="1">
                <template v-slot:loading>
                  <q-spinner-gears color="white" />
                </template>
              </q-img>
            </div>
          </template>
        </c-card>
      </div>
    </div>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'project-location',
  props: {
    param: {
      type: Object,
      default: () => ({
        plantCd: '',
        updateFlag: '',
      }),
    },
  },
  data() {
    return {
      file: null,
      imgHeight: '',
      mapSrc: require('@/assets/images/no-image.png'),
      grid: {
        columns: [
          {
            name: 'mapName',
            field: 'mapName',
            label: '작업장소명',
            align: 'left',
            sortable: true,
            required: true,
            style: 'width:300px',
            type: 'text',
          },
          {
            name: 'remark',
            field: 'remark',
            label: '설명',
            align: 'left',
            sortable: true,
            type: 'text',
          },
          {
            name: 'attach',
            field: 'attach',
            label: '지도 첨부',
            align: 'center',
            style: 'width:160px',
            type: 'attach',
            taskClassCd: 'WORK_MAP',
            keyText: 'sopMapId',
            sortable: false,
            imageRestriction: {
              width: 700,
              height: 520,
            }
          },
          {
            name: 'useFlag',
            field: 'useFlag',
            label: '사용여부',
            align: 'center',
            sortable: true,
            style: 'width:40px',
            type: 'check',
            true: 'Y',
            false: 'N',
          },
          {
            name: 'src',
            field: 'src',
            label: '미리보기',
            align: 'center',
            style: 'width:40px',
            type: 'custom',
            sortable: false
          },
        ],
        data: [],
      },
      useFlagItems: [
        { code: 'Y', codeName: '사용' },
        { code: 'N', codeName: '미사용' },
      ],
      editable: true,
      listUrl: '',
      saveUrl: '',
      isSave: false,
      ratio: 1
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    setImgHeight() {
      let returnText = '';
      returnText = this.imgHeight ? (Number(this.imgHeight.replace('px', '')) - 32) + 'px' : '500px'
      return returnText;
    },
    haveMap() {
      return this.mapSrc !== require('@/assets/images/no-image.png')
    }
  },
  methods: {
    init() {
      // role setting
      if(this.$store.getters.user.innerFlag === 'N') {
        this.editable = false;
      } else {
        this.editable = this.$route.meta.editable;
      }
      // url setting
      this.listUrl = selectConfig.sop.swp.map.list.url
      this.saveUrl = transactionConfig.sop.swp.map.save.url
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {plantCd: this.param.plantCd};
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    /* eslint-disable no-unused-vars */
    rowClick(row) {
      if (row && row.fileList && row.fileList.length > 0) {
        this.$comm.previewImage({
          sysAttachFileId: row.fileList[0].sysAttachFileId,
          contentType: row.fileList[0].contentType,
        }).then(_result => {
          this.mapSrc = _result;
        });
      } else {
        this.mapSrc = require('@/assets/images/no-image.png')
      }
    },
    add() {
      let date = new Date();
      if (!this.grid.data) {
        this.grid.data = [];
      }
      this.grid.data.splice(0, 0, {
        sopMapId: String(date.getTime()),  // 점검항목 일련번호
        plantCd: this.param.plantCd,  // 공사현장 코드
        companyCd: this.$store.getters.user.companyCd,
        mapName: '',  // 지도명
        remark: '',  // 설명
        ratio: 1,
        useFlag: 'Y',  // 사용여부
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        editFlag: 'C'
      })
    },
    saveMap() {
      if (this.$comm.validTable(this.grid.columns, this.grid.data)) {
        
        let saveData = this.grid.data.filter( x => {
          return x.editFlag == 'C' || x.editFlag == 'U'
        });
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?',
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$_.forEach(saveData, item => {
              item.regUserId = this.$store.getters.user.userId
              item.chgUserId = this.$store.getters.user.userId
            })
            
            this.isSave = !this.isSave
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveMapCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getList();
    },
    toggle(e) {
      const target = this.$refs['image'].$el; // e.target.parentNode.parentNode.parentNode
      this.$q.fullscreen.toggle(target)
        .then(() => {
          // success!
        })
        .catch(() => {
          // error!
        })
    },
    setMap(type, row, files) {
      if (files && files.length > 0) {
        let data = files[0];
        this.$comm.previewImage({
          sysAttachFileId: data.sysAttachFileId,
          contentType: data.contentType,
        }).then(_result => {
          row.mapSrc = _result;
          this.setCanvas(row)
        });
      }
    },
    setCanvas(row) {
      let image = new Image();
      image.src = row.mapSrc;
      image.onload = function () {
        row.ratio = image.width / image.height
      };
    },
  }
};
</script>
<style lang="sass">
.work-map-chip
  .q-chip__content
    display: none

</style>