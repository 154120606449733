var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7" },
        [
          _c(
            "c-table",
            {
              ref: "table",
              attrs: {
                title: "작업장소",
                tableId: "riskHazrd01",
                columns: _vm.grid.columns,
                data: _vm.grid.data,
                filtering: false,
                editable: _vm.editable,
                columnSetting: false,
                usePaging: false,
              },
              on: {
                uploadChange: _vm.setMap,
                getTableHeight: (height) => {
                  this.imgHeight = height
                },
              },
              scopedSlots: _vm._u([
                {
                  key: "customArea",
                  fn: function ({ props, col }) {
                    return [
                      col.name === "src"
                        ? [
                            _c("q-btn", {
                              attrs: {
                                round: "",
                                unelevated: "",
                                size: "6px",
                                color: "amber",
                                icon: "search",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.rowClick(
                                    props.row,
                                    props.pageIndex
                                  )
                                },
                              },
                            }),
                          ]
                        : void 0,
                    ]
                  },
                },
              ]),
            },
            [
              _c(
                "template",
                { slot: "table-button" },
                [
                  _c(
                    "q-btn-group",
                    { attrs: { outline: "" } },
                    [
                      _vm.editable
                        ? _c("c-btn", {
                            attrs: { label: "추가", icon: "add" },
                            on: { btnClicked: _vm.add },
                          })
                        : _vm._e(),
                      _vm.editable
                        ? _c("c-btn", {
                            attrs: {
                              isSubmit: _vm.isSave,
                              url: _vm.saveUrl,
                              param: _vm.grid.data,
                              mappingType: "PUT",
                              label: "저장",
                              icon: "save",
                            },
                            on: {
                              beforeAction: _vm.saveMap,
                              btnCallback: _vm.saveMapCallback,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5" },
        [
          _c(
            "c-card",
            {
              staticClass: "cardClassDetailForm",
              attrs: {
                title: "작업장소 지도 미리보기",
                noMarginPadding: true,
                height: _vm.setImgHeight,
              },
            },
            [
              _c(
                "template",
                { slot: "card-button" },
                [
                  _c(
                    "q-btn-group",
                    { attrs: { outline: "" } },
                    [
                      _vm.haveMap
                        ? _c("q-btn", {
                            staticClass: "custom-btn",
                            attrs: {
                              size: "md",
                              "text-color": "white",
                              color: "teal-6",
                              icon: _vm.$q.fullscreen.isActive
                                ? "fullscreen_exit"
                                : "fullscreen",
                              label: "지도 크게 보기",
                            },
                            on: { click: _vm.toggle },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("template", { slot: "card-detail" }, [
                _c(
                  "div",
                  { staticClass: "col-12" },
                  [
                    _c("q-img", {
                      ref: "image",
                      attrs: {
                        src: _vm.mapSrc,
                        fit: "scale-down",
                        contain: true,
                        ratio: 1,
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "loading",
                          fn: function () {
                            return [
                              _c("q-spinner-gears", {
                                attrs: { color: "white" },
                              }),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }